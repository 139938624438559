import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import GallerySlide from "../components/GallerySlide/GallerySlide"
import SectionHeadingH2 from "../components/SectionHeadingH2/SectionHeadingH2"
import photoCalidades from "../images/promociones/Residencial San Urbano/Calidades/calidades.jpg"
import photoSituacion from "../images/promociones/Residencial San Urbano/Situacion/situacion-mapa.jpg"

const SanUrbanoPage = () => {
  const images = useStaticQuery(graphql`
    query {
      photosDuplexSanUrbano: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Residencial San Urbano/Duplex (plano, precio, fotos)"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosDuplexPilotoSanUrbano: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Residencial San Urbano/Duplex piloto"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosEstudioSanUrbano: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Residencial San Urbano/Estudio (plano, precio y fotos)"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosEstudioPilotoSanUrbano: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Residencial San Urbano/Estudio piloto"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosZonasComunesSanUrbano: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Residencial San Urbano/Zonas comunes"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
    }
  `)

  const photosDuplex = images.photosDuplexSanUrbano.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosDuplexPiloto = images.photosDuplexPilotoSanUrbano.nodes.map(
    node => {
      return {
        image: node.childImageSharp.fluid,
        imageThumbnail: `${node.childImageSharp.fluid.src}`,
        imageModal: `${node.childImageSharp.fluid.src}`,
        id: node.childImageSharp.id,
      }
    }
  )

  const photosEstudio = images.photosEstudioSanUrbano.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosEstudioPiloto = images.photosEstudioPilotoSanUrbano.nodes.map(
    node => {
      return {
        image: node.childImageSharp.fluid,
        imageThumbnail: `${node.childImageSharp.fluid.src}`,
        imageModal: `${node.childImageSharp.fluid.src}`,
        id: node.childImageSharp.id,
      }
    }
  )

  const photosZonasComunes = images.photosZonasComunesSanUrbano.nodes.map(
    node => {
      return {
        image: node.childImageSharp.fluid,
        imageThumbnail: `${node.childImageSharp.fluid.src}`,
        imageModal: `${node.childImageSharp.fluid.src}`,
        id: node.childImageSharp.id,
      }
    }
  )

  return (
    <Layout>
      <div className="container">
        <SectionHeadingH2 sectionTitle="Residencial San Urbano" />
        <div className="text-component max-width-sm margin-x-auto">
          <p>
            Situada junto a un amplio paque con zonas verdes, esta promoción
            dispone de estudios y dúplex con piscina y jardines. Todas las
            viviendas cuentan con Bañera de Hidromasaje, cocina amueblada,
            materiales con calidades de lujo, aire acondicionado en estudios y
            preinstalación de aire acondicionado en dúplex.
          </p>
        </div>
        <div className="grid gap-xl margin-y-xl">
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">Duplex</p>
            <GallerySlide
              gallerySlideListItems={photosDuplex}
              idLightbox="photosDuplex"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Duplex piloto
            </p>
            <GallerySlide
              gallerySlideListItems={photosDuplexPiloto}
              idLightbox="photosDuplexPiloto"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Estudio
            </p>
            <GallerySlide
              gallerySlideListItems={photosEstudio}
              idLightbox="photosEstudio"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Estudio piloto
            </p>
            <GallerySlide
              gallerySlideListItems={photosEstudioPiloto}
              idLightbox="photosEstudioPiloto"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Zonas comunes
            </p>
            <GallerySlide
              gallerySlideListItems={photosZonasComunes}
              idLightbox="photosZonasComunes"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Calidades
            </p>
            <img src={photoCalidades} alt="" />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Ubicación
            </p>
            <img src={photoSituacion} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SanUrbanoPage
